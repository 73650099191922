@import '../../assets/stylesheets/global.scss';

.Template {
    width: calc(min(100vw, 1200px));
    height: 100vh;

    margin: 0 auto;

    padding: 25px;

    .desktop {
        display: none;
    }

    .mobile {
        display: inital;
    }

    .main-title {
        margin: 0;
        
        width: 100%;
        text-align: center;

        font-size: calc(min(7vw, 4rem));

        padding: 10px;
        padding-top: 0;
    }

    .mini-nav {

        width: 100%;

        hr {

            animation: 1s ease-in-out 0s 1 widen;

            @keyframes widen {
                0% {
                    width: 0%;
                }
                100% {
                    width: 100%;
                }
            }

        }

        .flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }

        .mini-nav-link {

            font-weight: lighter;
        
            font-size: 1.5rem;

        }

        .mini-nav-link-active {

            color: $palenight-functions;

        }

        .mini-nav-link-active:hover {

            cursor: default;

        }

    }
}

// from mobile => to desktop
@media only screen and (min-width: 700px) {

    .Template {

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }
        
    }

}

// from desktop => to mobile
@media only screen and (max-width: 0px) {}
