@import '../../assets/stylesheets/global.scss';

.ResumePage {

    animation: 0.5s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeInUp;

    .pdf-notification {
        position: fixed;
        bottom: 15pt;
        right: 15pt;

        background-color: $palenight-text;
        width: min(20vw, 75pt);
        height: min(12vw, 75pt);
        border-radius: 25pt 25pt 0 25pt;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        user-select: none;

        p {
            font-size: 1.5rem;
        }

        opacity: 0;

        animation: 1s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeInUp;
        animation-delay: 2000ms;
        animation-fill-mode: forwards;
    }

    .pdf-notification:hover {
        cursor: pointer;
    }

    .pdf-notification:active {
        animation: 1s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeOut;
        animation-direction: reverse;
    }

    .resume-section {

        h2 {
            text-align: center;
            font-size: 2.5rem;
        }

        .job-card {

            width: calc(min(700px, 100%));
            margin: 0 auto;
            margin-bottom: 50px;

            p {
                margin: 0;
            }

            .job-company {
                font-size: 1.4rem;
                color: $palenight-text;
            }

            .title-line {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                border-bottom: 1px solid $palenight-comments;

                .job-title {
                    font-size: 1.75rem;
                }

                .time-span {
                    font-size: 1.5rem;
                    color: lighten($palenight-text, 25);
                }
            }

            .job-description {
                text-align: justify;
                font-size: 1.3rem;
                color: $palenight-text;
            }

            .job-tags {

                margin: 5px 0;

                p {
                    display: inline-block;
                    font-size: 1.2rem;

                    border: 1px solid $palenight-comments;
                    border-radius: 3px;

                    padding: 0 5px;
                    margin-right: 5px;
                    margin-bottom: 3px;
                }
            }

        }

        .education-card {

            width: calc(min(700px, 100%));
            margin: 0 auto;
            margin-bottom: 50px;

            p {
                margin: 0;
            }

            .education-type {
                font-size: 1.4rem;
                color: $palenight-text;
            }

            .title-line {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;

                border-bottom: 1px solid $palenight-comments;

                .school-title {
                    font-size: 2rem;
                }

                .too-long {
                    font-size: 1.75rem;
                }

                .time {
                    font-size: 1.5rem;
                }
            }

            .job-description {
                text-align: justify;
                font-size: 1.3rem;
                color: $palenight-text;
            }

            .job-tags {

                margin: 5px 0;

                p {
                    display: inline-block;
                    font-size: 1.2rem;

                    border: 1px solid $palenight-comments;
                    border-radius: 3px;

                    padding: 0 5px;
                    margin-right: 5px;
                    margin-bottom: 3px;
                }
            }

        }

    }
}

// from mobile => to desktop
@media only screen and (min-width: 500px) {
    .ResumePage {
        .resume-section {
            .job-card {
                .title-line {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;
                }
            }
        }
    }
}

// from desktop => to mobile
@media only screen and (max-width: 0px) {}
