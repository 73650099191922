@import '../../assets/stylesheets/global.scss';

.AboutPage {

    animation: 0.5s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeInUp;

    .splash {

        width: 100%;

        height: calc(min(30vh, 200px));

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .splash-text {

            width: 80%;

            h2 {
                margin: 0;
            }

            .line-one {
                font-size: 1.75rem;
                text-align: center;
            }

            .line-two {
                margin: 0;
                font-size: 4.5rem;
                text-align: center;
            }

            .line-three {
                font-size: 1.75rem;
                text-align: center;

                color: $palenight-comments;

                opacity: 0;

                animation: 1s cubic-bezier(0.85, 0, 0.15, 1) 2s 3 brokenBlink forwards;
            }

        }

    }

    .time-calculated {
        font-family: "Roboto Mono";
        font-size: 1.25rem;
        color: lighten($palenight-comments, 25%);

        border: 1px solid $palenight-comments;
        border-radius: 3px;

        padding: 2px;
    }
    
    .about-intro {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        pre {
            font-family: "Roboto Mono";
            font-size: 1.25rem;

            counter-reset: line;

            margin-left: -15px;

            p {
                margin: 0;
                height: 2rem;
            }

            p:before {
                counter-increment: line;
                content: counter(line);

                color: $palenight-comments;

                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;

                width: 4rem;

                border-right: 1px solid $palenight-comments;

                padding: 15px;
                margin-right: 15px;
            }
        }
    }

    .about-content {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
            margin: 0;
            width: calc(min(100%, 800px));

            text-align: center;

            h3 {
                display: inline-block;
                border-bottom: 1px solid $palenight-comments;
                padding-bottom: 5px;
                margin-bottom: 0;

                font-size: calc(min(5.5vw, 3rem));
            }

            p {
                text-align: center;
                font-size: 1.15rem;
            }
        }

        .skills {

            h3 {
                margin-top: 50px;
                padding-bottom: 10px;
            }

            .three-item-banner {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .item {

                    width: calc(min(20vw, 100px));
                    margin: 0 auto;

                    .svg-icon {
                        width: 85%;
                    }

                    h4 {
                        font-size: 1.25rem;
                    }

                }
            }

            p {
                text-align: justify;
            }

            .item-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px 0;

                margin: 10px auto;

                padding: 10px;

                .item {

                    width: calc(min(15vw, 100px));

                    margin: 0 auto;

                    .svg-icon {
                        width: 85%;
                    }

                    h4 {
                        margin: 0;
                        font-size: 1.25rem;
                    }

                }
            }

            .tag-line {
                color: $palenight-comments;
                text-align: center;

                font-size: 1rem;
            }

        }

        .personal {

            p {
                text-align: justify;
            }

            .hobby-section {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .hobby-card {

                    text-align: center;

                    p {
                        font-size: 1.3rem;
                        margin: 0;

                        display: inline-block;

                        border-bottom: 1px solid $palenight-comments;
                    }
                    
                    ul {

                        margin: 0;
                        margin-bottom: 25px;

                        li {
                            font-size: 1.1rem;

                            &::before {
                                content: " ⁃ ";
                            }
                        }

                    }
                }

            }

        }
    }

}

// from mobile => to desktop
@media only screen and (min-width: 700px) {

    .AboutPage {

        .about-content {

            .skills {

                .item-grid {

                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 20px 0;

                }

            }

            .personal {

                .hobby-section {

                    flex-direction: row;
                    align-items: center;

                }

            }

        }
        
    }

}

// from desktop => to mobile
@media only screen and (max-width: 0px) {}
