/* this file is loaded many times */

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Light.ttf");
    font-weight: lighter;
}

@font-face {
    font-family: "Roboto Mono";
    src: url("../fonts/RobotoMono/RobotoMono-VariableFont_wght.ttf");
}

// palenight theme
$palenight-background: #292d3d;
$palenight-comments: #666d93;
$palenight-text: #676E95;
$palenight-links: #80cbc4;
$palenight-functions: #80a8ff;
$palenight-keywords: #c894eb;
$palenight-tags: #f07076;
$palenight-strings: #c3e88d;
$palenight-operators: #8adeff;
$palenight-attributes: #ffcb6b;
$palenight-numbers: #f78e6e;

$redux-purple: #764abc;