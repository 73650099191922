@import "./global.scss";

body {

    scroll-behavior: smooth;

    background-color: $palenight-background;
    color: white;

    font-family: "Poppins", Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 16pt;

}

hr {
    color: $palenight-comments;
}

Link, a {

    color: $palenight-links;

    transition: all 0.25s;

    text-decoration: none;
    
}

Link:visited, a:visited {

    color: $palenight-links;

}

Link:hover, a:hover {

    color: darken($palenight-links, 25%);

}

.not-found-page {

    text-align: center;
    padding: 5vw;

    p {
        margin: 0;
        font-size: 2rem;
    }

    .shrug {
        font-family: monospace;
        text-decoration: none;
    }

    a {
        font-size: 1.5rem;
        text-decoration: underline;
    }

}

.comments {
    color: $palenight-comments;
}

.keywords {
    color: $palenight-keywords;
}

.functions {
    color: $palenight-functions;
}

.tags {
    color: $palenight-tags;
}

.attributes {
    color: $palenight-attributes;
}

.operators {
    color: $palenight-operators;
}

.strings {
    color: $palenight-strings;
}

.numbers {
    color: $palenight-numbers;
}

.redux {
    color: $redux-purple;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0,5px,0)
    }
    to {
        opacity: 1;
        transform: translate3d(0,0,0)
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes brokenBlink {
    0% { opacity: 1;}
    5% { opacity: 0.1;}
    10% { opacity: 1;}
    15% { opacity: 0.1;}
    25% { opacity: 1;}
    30% { opacity: 0.1;}
    35% { opacity: 1;}
    75% { opacity: 0.1;}
    80% { opacity: 1;}
    100% { opacity: 1;}
}

// from mobile => to desktop
@media only screen and (max-width: 800px) {

    html {

        font-size: 8pt;
    
    }

}