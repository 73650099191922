@import '../../assets/stylesheets/global.scss';

.ContactPage {

    animation: 0.5s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeInUp;

    padding-bottom: 50px;

    .my-card {

        width: calc(min(80vw, 400px));
        height: calc(min(50.4vw, 252px));

        margin: 50px auto;

        background-color: rgb(31, 30, 30);
        border-radius: 2vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 12px;

        .logo {
            margin-bottom: calc(min(5vw, 25px));
            animation: 10s linear 0s infinite spin;

            .box {
                padding: 5px;
                animation: 10s cubic-bezier(.4,1,.6,0) 0s reverse infinite spin;
                border-radius: 3px;

                border: calc(min(.5vw, 3px)) solid transparent;
            }

            .box-1 {
                border-color: $palenight-text;
            }

            .box-2 {
                border-color: $palenight-links;
            }

            .box-3 {
                border-color: $palenight-functions;
            }

            .box-4 {
                border-color: $palenight-keywords;
            }

            .box-5 {
                border-color: $palenight-attributes;
            }

            .box-6 {
                border-color: $palenight-numbers;
            }

            .center {
                border-color: $palenight-tags;
                height: 5px;
                width: 5px;

                border-radius: 1px;

                animation: 10s cubic-bezier(.4,1,.6,0) 0s reverse infinite spin;
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        span {
            margin: 0;
            padding: 0;

            &:nth-child(2) {
                font-size: 1.2rem;
            }

            &:nth-child(3) {
                font-size: 0.85rem;
            }

            &:nth-child(4) {
                font-size: 0.85rem;
            }
        }
    }

    h2 {
        font-size: 2.5rem;
        text-align: center;
    }

    h3 {
        font-size: 1.5rem;
        text-align: center;
    }

    .email-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: calc(min(500px, 100%));
        margin: 0 auto;

        p {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1rem !important;
        }

        .copy-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            a {
                margin: 10px;
                padding: 0;
                display: block;
            }

            i {
                cursor: pointer;
                user-select: none;

                transform: scale(1);
                transition: all 0.1s linear;

                &:hover {
                    transform: scale(1.25);
                }

                &:active {
                    color: darken(white, 20%)
                }
            }
        }
    }



    .email-form {

        animation: 0.75s cubic-bezier(0.87, 0, 0.13, 1) 0s 1 fadeInUp;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: calc(min(80vw, 400px));
        height: calc(min(50.4vw, 252px));

        margin: 50px auto;

        background-color: rgb(31, 30, 30);
        border-radius: 2vw;

        padding: 10px 25px 25px 25px;
        margin: 25px auto 15px auto;

        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 12px;

        .inputs {
            width: 80%;
        }

        .input-field {
            width: 100%;

            label {
                color: $palenight-comments;
            }

            textarea {
                color: lighten($palenight-text, 25%);

                border-bottom: 1px solid $palenight-comments;
                box-shadow: 0 1px 0 0 $palenight-comments;
            }

            textarea:focus {
                border-bottom: 1px solid $palenight-keywords;
                box-shadow: 0 1px 0 0 $palenight-keywords;
            }

            i {
                color: $palenight-comments;
            }

            .active {
                color: $palenight-keywords;
            }
        }

    }

    .email-animation {
        animation: 0.25s emailOut 1 linear;
        opacity: 0;
    }

    @keyframes emailOut {
        0% {
            opacity: 1;
            transform: translate(0, 0);
        }

        100% {
            opacity: 0;
            transform: translate(50px, 0);
        }
    }

    button {
        background-color: transparent;
        
        border: 1px solid transparent;
        border-radius: 5px;
        border-color: $palenight-attributes;

        color: $palenight-attributes;

        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 12px;

        transition: all 0.1s linear;

        &:hover {
            background-color: transparent;
            border-color: darken($palenight-attributes, 10%);
            color: darken($palenight-attributes, 10%);
        }

        &:focus {
            background-color: transparent;
            border-color: darken($palenight-attributes, 10%);
            color: darken($palenight-attributes, 10%);
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.25);
            border-color: darken($palenight-attributes, 20%);
            color: darken($palenight-attributes, 20%);
        }
    }

    .platform-card-container-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .platform-card-container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-top: 1px solid $palenight-comments;

        .platform-card {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-evenly;
            margin: 2pt;
            padding: 10pt;
            border-radius: 10pt;

            h4 {
                font-size: 1rem;
                color: darken($palenight-text, 10);
                margin: 0;
            }

            :hover {
                cursor: pointer;
            }

            svg {
                fill: $palenight-comments;
            }
        }
    }
}

// from mobile => to desktop
@media only screen and (min-width: 0px) {}

// from desktop => to mobile
@media only screen and (max-width: 0px) {}
